<template>
  <div class="g-page-container">
    <div class="g-pageHeader" style="height: 63px">
      <h1 style="margin: auto">{{ $t("factorySetup.title") }}</h1>
    </div>
    <ReturnButtonContainer to="/config" />
    <form
      class="g-pageBody"
      @submit.prevent="() => onSubmit($t('factorySetup.setup'))"
    >
      <h1>{{ $t("factorySetup.setup") }}</h1>
      <hr class="marginTop40px" />

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="bufferSchedule"
              >{{ $t("factorySetup.startDay")
              }}<small>{{ $t("factorySetup.inMinutes15") }}</small></label
            >
            <input
              v-model="factorySetup.bufferStart"
              id="bufferSchedule"
              type="number"
              min="0"
              max="30"
              value="45"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="decimalsSetup">{{ $t("factorySetup.decimals") }}</label>
            <input
              v-model="factorySetup.decimalsForActual"
              id="decimalsSetup"
              type="number"
              min="0"
              max="2"
              step="1"
              value="1"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="setupLunch"
              >{{ $t("factorySetup.lunch")
              }}<small>{{ $t("factorySetup.inMinutes90") }}</small></label
            >
            <input
              v-model="factorySetup.lunchMinutes"
              id="setupLunch"
              type="number"
              min="0"
              max="90"
              value="60"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="minEarned">{{ $t("factorySetup.minEarned") }}</label>
            <input
              v-model="factorySetup.standardChangeBonus"
              id="minEarned"
              type="number"
              min="0"
              value="0"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="setupBreak"
              >{{ $t("factorySetup.break")
              }}<small>{{ $t("factorySetup.inMinutes90") }}</small></label
            >
            <input
              v-model="factorySetup.breakMinutes"
              id="setupBreak"
              type="number"
              min="0"
              max="90"
              value="15"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input
              type="checkbox"
              id="breaksIncluded"
              v-model="factorySetup.breaksIncluded"
            />
            <label class="checkboxLabel" for="breaksIncluded">{{
              $t("factorySetup.breaksInc")
            }}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input
              type="checkbox"
              id="scanned"
              v-model="factorySetup.sameScanned"
            />
            <label class="checkboxLabel" for="scanned">{{
              $t("factorySetup.scanned")
            }}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input
              type="checkbox"
              id="moveToLine"
              v-model="factorySetup.moveToLine"
            />
            <label class="checkboxLabel" for="moveToLine">{{
              $t("factorySetup.moveToLine")
            }}</label>
          </div>
        </div>
      </div>

      <button
        class="btn btn-perfcell float-left marginTop50px"
        @click.prevent="clickRetour"
      >
        {{ $t("factorySetup.cancel") }}
      </button>
      <button
        class="btn btn-perfcell float-left marginTop50px marginLeft10px"
        type="submit"
      >
        {{ $t("factorySetup.submit") }}
      </button>
    </form>

    <form
      class="g-pageBody marginTop100px"
      @submit.prevent="() => onSubmit($t('factorySetup.custom'))"
    >
      <h1>{{ $t("factorySetup.custom") }}</h1>
      <hr class="marginTop40px" />

      <div v-for="(custom, index) in factorySetup.customBonuses" :key="index">
        <hr v-if="index != 0" />
        <b-row>
          <b-col cols="3">
            <div class="form-group">
              <label for="customMinutes" class="marginTop10px">{{
                $t("factorySetup.minutes")
              }}</label>
              <input
                id="customMinutes"
                type="number"
                v-model="custom.minutes"
                min="0"
                class="form-control"
              />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <div class="input-logo">
                <b-row>
                  <label for="inputLogo" class="marginTop10px">{{
                    $t("factorySetup.icon")
                  }}</label>
                </b-row>
                <b-row>
                  <div class="btn btn-file btn-background">
                    <label for="inputLogo" class="btn-label">
                      {{ $t("factorySetup.browse") }}
                      <i class="fa fa-upload" aria-hidden="true"></i>
                    </label>
                    <input
                      type="file"
                      accept="image/png"
                      class="upload-btn"
                      id="inputLogo"
                      ref="browseFile"
                      @change="
                        previewLogo($event, index, $t('factorySetup.custom'))
                      "
                    />
                  </div>
                  <!-- <div class="btn btn-file btn-background">
                    {{ $t("factorySetup.browse") }}
                    <i class="fa fa-upload" aria-hidden="true"></i>
                    <input
                      title="babar"
                      type="file"
                      accept="image/png"
                      class="upload-btn"
                      id="inputLogo"
                      ref="browseFile"
                      @change="
                        previewLogo($event, index, $t('factorySetup.custom'))
                      "
                    />
                  </div> -->
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col cols="5">
            <img
              :src="factorySetup.customBonuses[index].logoFile"
              @click="clickLogoImage(index)"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="9">
            <div class="form-group">
              <label>{{ $t("factorySetup.desc") }}</label>
              <input
                type="text"
                v-model="custom.description"
                class="form-control"
              />
            </div>
          </b-col>
          <b-col cols="3">
            <button
              class="btn btn-perfcell"
              @click.prevent="clickDeleteCustom(index)"
            >
              {{ $t("factorySetup.delete") }}
            </button>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col cols="12">
          <button
            class="btn btn-perfcell marginTop10px"
            @click.prevent="clickNewCustom"
          >
            {{ $t("factorySetup.new") }}
          </button>
        </b-col>
      </b-row>

      <button
        class="btn btn-perfcell float-left marginTop50px"
        @click.prevent="clickRetour"
      >
        {{ $t("factorySetup.cancel") }}
      </button>
      <button
        class="btn btn-perfcell float-left marginTop50px marginLeft10px"
        type="submit"
      >
        {{ $t("factorySetup.submit") }}
      </button>
    </form>

    <form
      class="g-pageBody marginTop100px"
      @submit.prevent="() => onSubmit($t('factorySetup.quality'))"
    >
      <h1>{{ $t("factorySetup.quality") }}</h1>
      <hr class="marginTop40px" />

      <div v-for="(quality, index) in factorySetup.qualityChecks" :key="index">
        <hr v-if="index != 0" style="margin: 20px" />

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="inspector">{{ $t("factorySetup.inspector") }}</label>
              <div id="inspector">
                <MultipleSelect
                  :data="inspectorList"
                  v-model="factorySetup.qualityChecks[index].inspectQuality"
                  :options="optInspector"
                >
                </MultipleSelect>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="inspector">{{ $t("factorySetup.lines") }}</label>
              <div id="inspector">
                <MultipleSelect
                  multiple
                  :data="lineList"
                  v-model="factorySetup.qualityChecks[index].linesQuality"
                  :options="optLine"
                >
                </MultipleSelect>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="inspector">{{ $t("factorySetup.sched") }}</label>
              <div id="inspector">
                <MultipleSelect
                  multiple
                  :data="schedList"
                  v-model="factorySetup.qualityChecks[index].schedulesQuality"
                  :options="optSched"
                >
                </MultipleSelect>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <label>
              <input
                v-model="factorySetup.qualityChecks[index].fixedQuality"
                id="fixedQuality"
                type="checkbox"
              />
              {{ $t("factorySetup.fixedQuality") }}
            </label>
          </div>
          <div class="col-md-2">
            <label for="nbFixedQuality" style="margin-top: 2px">{{
              $t("factorySetup.afterEvery")
            }}</label>
          </div>
          <div>
            <label>
              <input
                v-model="factorySetup.qualityChecks[index].nbFixedQuality"
                id="nbFixedQuality"
                type="number"
                step="1"
                min="1"
                style="width: 80px"
                value="30"
              />
              {{ $t("factorySetup.nbFixedQuality") }}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <label>
              <input
                v-model="factorySetup.qualityChecks[index].randomQuality"
                id="randomQuality"
                type="checkbox"
              />
              {{ $t("factorySetup.randomQuality") }}
            </label>
          </div>
          <div class="col-md-2"></div>
          <div>
            <label>
              <input
                v-model="factorySetup.qualityChecks[index].nbRandomQuality"
                id="nbRandomQuality"
                type="number"
                step="1"
                min="1"
                style="width: 80px"
              />
              {{ $t("factorySetup.nbRandomQuality") }}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label>
              <input
                v-model="factorySetup.qualityChecks[index].msgInspectorQuality"
                id="msgInspectorQuality"
                type="checkbox"
              />
              {{ $t("factorySetup.msgInspectorQuality") }}
            </label>
          </div>
          <div class="col-md-4">
            <label>
              <input
                v-model="factorySetup.qualityChecks[index].msgTabletQuality"
                id="msgTabletQuality"
                type="checkbox"
              />
              {{ $t("factorySetup.msgTabletQuality") }}
            </label>
          </div>
        </div>
      </div>

      <button
        class="btn btn-perfcell float-left marginTop50px"
        @click.prevent="clickRetour"
      >
        {{ $t("factorySetup.cancel") }}
      </button>
      <button
        class="btn btn-perfcell float-left marginTop50px marginLeft10px"
        type="submit"
      >
        {{ $t("factorySetup.submit") }}
      </button>
    </form>
  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import { axiosInstanceReports } from "@/main";
import EventBus from "@/eventBus";
import oper from "@/stores/Operations.js";
import { baseURL } from "../main";
import ReturnButtonContainer from "../components/Return-button/Return-button-container.vue";
export default {
  components: {
    ReturnButtonContainer,
  },
  data() {
    return {
      factorySetup: {
        decimalsForActual: 0,
        bufferStart: 0,
        breaksIncluded: false,
        sameScanned: false,
        // needTask: false,
        customBonuses: [],
        qualityChecks: [],
        moveToLine: false,
      },

      // constant that determine the maximum number of qualityChecks for the whole Perfcell
      MAX_QUAL_CHECK: 2,

      errors: [],
      msgColor: "red",

      inspectorList: [],
      lineList: [],
      schedList: [],

      optInspector: {
        multiple: false,
        filter: true,
        placeholder: this.$i18n.t("factorySetup.inspPHolder"),
        multipleWidth: 200,
        width: 200,
        dropWidth: 200,
        locale: localStorage.getItem("locale"),
      },
      optLine: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("factorySetup.linesPHolder"),
        multipleWidth: 200,
        width: 200,
        dropWidth: 200,
        locale: localStorage.getItem("locale"),
      },
      optSched: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("factorySetup.schedPHolder"),
        multipleWidth: 200,
        width: 200,
        dropWidth: 200,
        locale: localStorage.getItem("locale"),
      },
      isGoodfood: false,
    };
  },

  methods: {
    makeToast(append, form, message, variant) {
      // this.$bvToast.toast(message, {
      //   title: form,
      //   autoHideDelay: 5000,
      //   appendToast: append,
      //   toaster: "b-toaster-top-center",
      //   variant: variant,
      // });
      const h = this.$createElement;
      const vNodesTitle = h("div", { class: ["toastHead"] }, message);
      const vNodesMsg = h("div", { class: ["toastBase"] }, form);
      this.$bvToast.toast([vNodesTitle], {
        // id: this.toastId,
        title: [vNodesMsg],
        variant: variant,
        toaster: "b-toaster-top-center",
        noCloseButton: false,
        // autoHideDelay: 6000,
      });
    },
    previewLogo(event, index, form) {
      var file = event.target.files;
      let size = file[0].size;
      if (size > 1048576) {
        this.makeToast(
          true,
          form,
          this.$i18n.t("factorySetup.errorMsg"),
          "danger"
        );
        return;
      }
      if (!file.length) return;
      let fName = file[0].name;
      //console.log("----- file name = " + fName);
      this.factorySetup.customBonuses[index].fileName = fName;
      this.factorySetup.customBonuses[index].newLogoFile = true;
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (e) => {
        // store the base64 image for display on screen and later on for transmit to server
        this.factorySetup.customBonuses[index].logoFile = e.target.result;
        this.$forceUpdate();
      };
    },
    clickLogoImage: function (index) {
      //console.log("----- clickLogoImage = " + index);
      this.$refs.browseFile[index].click();
    },
    onSubmit(form) {
      if (!this.validate(form)) return;

      axiosInstance
        .post("/factorySetup", this.factorySetup)
        .then(() => {
          this.makeToast(
            true,
            form,
            this.$i18n.t("factorySetup.success"),
            "success"
          );
          axiosInstanceReports.post("/rep/factorySetup", {});
        })
        .catch((e) => {
          this.makeToast(true, form, e, "danger");
        });
    },
    validate(form) {
      this.errors = [];
      this.msgColor = "red";

      this.factorySetup.lunchMinutes = Number(this.factorySetup.lunchMinutes);
      this.factorySetup.breakMinutes = Number(this.factorySetup.breakMinutes);
      this.factorySetup.standardChangeBonus = Number(
        this.factorySetup.standardChangeBonus
      );
      this.factorySetup.decimalsForActual = Number(
        this.factorySetup.decimalsForActual
      );
      this.factorySetup.bufferStart = Number(this.factorySetup.bufferStart);

      if (this.factorySetup.lunchMinutes > 90)
        this.errors.push(this.$i18n.t("factorySetup.maxLunch"));
      if (this.factorySetup.breakMinutes > 90)
        this.errors.push(this.$i18n.t("factorySetup.maxBreak"));
      if (this.factorySetup.decimalsForActual > 2)
        this.errors.push(this.$i18n.t("factorySetup.maxDecimals"));
      if (this.factorySetup.bufferStart > 30)
        this.errors.push(this.$i18n.t("factorySetup.maxBuffer"));

      this.factorySetup.customBonuses.forEach((f) => {
        if (f.fileName.length > 4)
          if (
            f.fileName.substr(f.fileName.length - 4, 4).toLowerCase() != ".png"
          )
            this.errors.push(this.$i18n.t("factory.png"));
      });

      this.factorySetup.qualityChecks.forEach((f) => {
        if (f.fixedQuality && (!f.nbFixedQuality || f.nbFixedQuality < 1)) {
          if (!this.errors.includes(this.$i18n.t("factorySetup.zeroNbFixed")))
            this.errors.push(this.$i18n.t("factorySetup.zeroNbFixed"));
        }
        if (f.randomQuality && (!f.nbRandomQuality || f.nbRandomQuality < 1)) {
          if (!this.errors.includes(this.$i18n.t("factorySetup.zeroNbRandom")))
            this.errors.push(this.$i18n.t("factorySetup.zeroNbRandom"));
        }
        if (f.randomQuality || f.fixedQuality) {
          if (!f.msgInspectorQuality && !f.msgTabletQuality) {
            if (
              !this.errors.includes(this.$i18n.t("factorySetup.noDestination"))
            )
              this.errors.push(this.$i18n.t("factorySetup.noDestination"));
          }
          if (!f.inspectQuality) {
            if (!this.errors.includes(this.$i18n.t("factorySetup.noInspector")))
              this.errors.push(this.$i18n.t("factorySetup.noInspector"));
          }
          if (f.linesQuality.length == 0) {
            if (!this.errors.includes(this.$i18n.t("factorySetup.noLine")))
              this.errors.push(this.$i18n.t("factorySetup.noLine"));
          }
          if (f.schedulesQuality.length == 0) {
            if (!this.errors.includes(this.$i18n.t("factorySetup.noSchedule")))
              this.errors.push(this.$i18n.t("factorySetup.noSchedule"));
          }
        }
      });
      this.errors.forEach((e) => {
        this.makeToast(true, form, e, "danger");
      });
      return !this.errors.length;
    },
    clickRetour() {
      this.$router.replace("/config");
    },
    clickNewCustom() {
      if (this.factorySetup.customBonuses.length == 6) {
        this.$bvModal.msgBoxOk(this.$i18n.t("factorySetup.max6"), {
          okTitle: this.$i18n.t("ok"),
        });
      } else {
        this.factorySetup.customBonuses.push({
          minutes: 1,
          description: "",
          myIcon: "default-icon.png",
          // followings are not part of the factorySetup
          logoFile: baseURL + "/images/customIcons/default-icon.png",
          fileName: "",
          newLogoFile: false,
        });
      }
    },
    clickDeleteCustom(index) {
      let title = this.factorySetup.customBonuses[index].description;
      if (title.length == 0) title = " ";
      this.$bvModal
        .msgBoxConfirm(title, {
          title: this.$i18n.t("factorySetup.confirmDelete"),
          okTitle: this.$i18n.t("ok"),
          cancelTitle: this.$i18n.t("cancel"),
        })
        .then((value) => {
          if (value) {
            this.factorySetup.customBonuses.splice(index, 1);
          }
        });
    },
    configUpdateFactorySetup(factorySetup) {
      //console.log("----- config configUpdateFactorySetup = " + JSON.stringify(factorySetup, null, 2));
      if (factorySetup) {
        this.factorySetup = factorySetup;

        if (!this.factorySetup.customBonuses)
          this.factorySetup.customBonuses = [];
        for (let i = 0; i < this.factorySetup.customBonuses.length; i++) {
          let custom = this.factorySetup.customBonuses[i];
          custom.logoFile = baseURL + "/images/customIcons/" + custom.myIcon;
          custom.newLogoFile = false;
          custom.fileName = "";
        }

        if (
          !this.factorySetup.qualityChecks ||
          this.factorySetup.qualityChecks.length == 0
        ) {
          this.factorySetup.qualityChecks = [{}, {}];
        }
        // add missing entries
        for (
          let i = this.factorySetup.qualityChecks.length;
          i < this.MAX_QUAL_CHECK;
          i++
        ) {
          this.factorySetup.qualityChecks.push({});
        }
      }
    },
  },
  mounted() {
    this.configUpdateFactorySetup(oper.getFactorySetup());

    if (process.env.VUE_APP_CLIENT_NAME.startsWith("goodfood"))
      this.isGoodfood = true;
    else this.isGoodfood = false;

    axiosInstance
      .get("/smsRecipientList", { params: { isActive: true } })
      .then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].text = resp.data[i].name + " - " + resp.data[i].phone;
          resp.data[i].value = resp.data[i]._id;
        }
        this.inspectorList = resp.data;
      })
      .catch((err) => {
        console.log(
          "----- get /smsRecipientList error = " + JSON.stringify(err, null, 2)
        );
      });

    axiosInstance
      .get("/lineList", { params: { isActive: true } })
      .then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].text =
            resp.data[i].name + " - " + resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        this.lineList = resp.data;
      })
      .catch((err) => {
        console.log(
          "----- get /lineList error = " + JSON.stringify(err, null, 2)
        );
      });

    axiosInstance
      .get("/scheduleList", { params: { isActive: true } })
      .then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].text = resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        let list = JSON.stringify(resp.data);
        this.schedList = JSON.parse(list);
      })
      .catch((err) => {
        console.log(
          "----- get /scheduleList error = " + JSON.stringify(err, null, 2)
        );
      });

    EventBus.$on("factorySetup", this.configUpdateFactorySetup);
  },
  beforeDestroy() {
    EventBus.$off("factorySetup", this.configUpdateFactorySetup);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";

.toastHead {
  width: auto;
  height: 30px;
  font-size: 20px;
  text-align: center;
}

.toastBase {
  width: auto;
  height: 50px;
  font-size: 20px;
  text-align: center;
}
form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

h1 {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.1;
  color: $perfcell-green;
  margin: 0;
}

.input-logo {
  display: inline-block;
}

img {
  display: inline-block;
  max-height: 100px;
}
.btn-file input[type="file"] {
  position: absolute;
  // top: 0;
  right: 0;
  //min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  width: 5vw !important;
  // display: block;
}
.btn-background {
  background-color: $perfcell-green;
  color: white;
  // margin-top: 40px;
  position: relative;
}
</style>
